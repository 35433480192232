<template>
	<b-dropdown
		position="is-bottom-left"
		aria-role="menu"
	>
		<template #trigger>
			<button class="btn-userarea">
				<UIImage
					:src="foto"
					size="34:34"
				/>
			</button>
		</template>


		<b-dropdown-item
			:has-link="false"
			custom
			class="userarea-id pt-0"
		>
			<div class="columns">
				<div class="column img-col">
					<UIImage
						:src="foto"
						size="64:64"
						class="user-icon"
					/>
				</div>
				<div class="column">
					<div>Olá, {{ alunoNome }}!</div>
					<div class="usernumber">
						Matricula: {{ alunoMatricula }}
					</div>
				</div>
			</div>
		</b-dropdown-item>

		<b-dropdown-item
			v-if="getInvalidados.length > 0 || getNaoEnviados.length > 0"
			id="doc-button-item"
			class="mt-3"
			:has-link="true"
		>
			<small id="doc-small-text">Você não concluiu a entrega de documentos</small>

			<Anchor
				v-if="linkDocumentos != undefined"
				id="doc-big-button"
				class="dropdown-link"
				:href="linkDocumentos.href"
				:target="linkDocumentos.target"
			>
				Entregar agora
			</Anchor>
		</b-dropdown-item>
		<hr v-if="getInvalidados.length > 0 || getNaoEnviados.length > 0">
		<b-dropdown-item :has-link="true">
			<Anchor
				v-if="linkDocumentos != undefined"
				class="dropdown-link item-shortcut"
				:href="linkDocumentos.href"
				:target="linkDocumentos.target"
			>
				<Icon
					:file="linkDocumentos.icon"
					:size="20"
				/>
				<span class="ml-3 mr-3">{{ linkDocumentos.label }}</span>
				<Icon
					v-if="getInvalidados.length > 0 || getNaoEnviados.length > 0"
					class="icon-warning"
					file="warning"
					:size="20"
				/>
			</Anchor>
		</b-dropdown-item>

		<b-dropdown-item :has-link="true">
			<Anchor
				v-if="linkPagamentos != undefined"
				class="dropdown-link item-shortcut"
				:href="linkPagamentos.href"
				:target="linkPagamentos.target"
			>
				<Icon
					:file="linkPagamentos.icon"
					:size="20"
				/>
				<span class="ml-3 mr-3">{{ linkPagamentos.label }} </span>
				<Icon
					v-if="getAtrasados.length > 0 || getAbertos.length > 0"
					class="icon-warning"
					file="warning"
					:size="20"
				/>
			</Anchor>
		</b-dropdown-item>

		<b-dropdown-item :has-link="true">
			<Anchor
				v-if="linkSolicitacoes != undefined"
				class="dropdown-link item-shortcut"
				:href="linkSolicitacoes.href"
				:target="linkSolicitacoes.target"
			>
				<Icon
					:file="linkSolicitacoes.icon"
					:size="20"
				/>
				<span class="ml-3">{{ linkSolicitacoes.label }}</span>
			</Anchor>
		</b-dropdown-item>
		<hr>
		<b-dropdown-item
			v-for="(menuItem, index) in links.userMenu"
			:key="index"
			:has-link="true"
		>
			<a
				v-if="get(menuItem, 'visible', true)"
				class="dropdown-link has-text-left"
				:href="get(menuItem, 'href', '/')"
				:target="get(menuItem, 'target', '_self')"
			>
				{{ get(menuItem, 'label', 'untitle') }}
			</a>
		</b-dropdown-item>
		<hr>

		<b-dropdown-item :has-link="true">
			<a
				class="dropdown-link item-shortcut"
				href="#"
				@click="clickNotificationBtn"
			>
				<div>
					<Icon
						file="bell"
						:size="20"
					/>
					<span
						v-if="hasNewNotification"
						class="notification-badge"
					/>
				</div>

				<span class="ml-3">Minhas notificações</span>
			</a>
		</b-dropdown-item>

		<b-dropdown-item
			:has-link="false"
			class="btn-other-action logout-dropdown-item has-text-right mt-4"
			custom
			paddingless
		>
			<router-link
				id="logout-button"
				class="dropdown-link"
				:to="{ name: 'login.logoff' }"
			>
				Sair
			</router-link>
		</b-dropdown-item>
	</b-dropdown>
</template>

<script>
import UIImage from '@components/UIImage';
import { get } from 'lodash';
import http from '@commons/http';
import { mapGetters, mapState } from 'vuex'
import Icon from '@components/Icon';
import Anchor from './Anchor.vue';

export default {
	name: 'ProfileDropdown',
	components: {
		UIImage,
		Icon,
		Anchor,
	},
	props: {
		hasNewNotification: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data() {
		return {
			links: {
				userMenu: []
			},
		};
	},
	computed: {
		...mapGetters('documentos', [
			'getInvalidados',
			'getNaoEnviados'
		]),
		...mapGetters('pagamentos', [
			'getAbertos',
			'getAtrasados'
		]),
		...mapGetters('servicos', [
			'getAcessosByName',
			'getAcessoDocumentos',
			'getAcessoPagamentos',
		]),
		...mapGetters('aluno/dadosPessoais', [
			'foto'
		]),
		...mapState({
			alunoNome: state => {
				const nomes = state.alunoNome.split(' ');
				nomes.splice(1, nomes.length - 2);
				return nomes.join(' ');
			},
			alunoMatricula: 'alunoMatricula',
		}),
		linkDocumentos(){
			return this.getAcessoDocumentos;
		},
		linkPagamentos() {
			return this.getAcessoPagamentos;
		},
		linkSolicitacoes() {
			return this.getAcessosByName(['solicitações'])[0];
		}
	},
	async created() {

		this.getLinksUsuarios();

		await this.$store.dispatch('aluno/dadosPessoais/carregarDados');
	},
	methods: {
		get,
		getLinksUsuarios() {
			http.get('/v1/aluno/links-usuarios').then(({ data }) => {
				this.links.userMenu = data.message;
			});
		},
		clickNotificationBtn() {
			this.$emit("clickNotificationBtn");
		},
	}
};
</script>

<style lang="scss" scoped>
@import '@styles/_variables';

.img-col {
	max-width: 64px;
}

.btn-notification {
	width: 34px;
	height: 34px;
	border-radius: 60px;
	border: 0 none;
	position: relative;
	background: transparent;
	cursor: pointer;
	margin: 0 8px;

	@media (max-width: 768px) {
		margin: 0 12px 0 0;
	}

	.image-icon {
		overflow: visible;
		width: 100%;
		height: auto;

		&:before {
			transition: all 200ms cubic-bezier(0.095, 0.31, 0.395, 1.44) 0ms;
			transform: scale(0);
			background-color: #37c36f;
			border-radius: 4px;
			position: absolute;
			content: '';
			width: 8px;
			height: 8px;
			z-index: 1;
			left: 16px;
			top: -1px;
		}
	}

	&.has-notification .image-icon:before {
		transform: scale(1);
	}
}

.user-icon {
	background: #eee;
	padding: 0;
	overflow: hidden;
	border-radius: 20px;
	box-shadow: 0 0 0 0 #fff, 0 0 0 0 $primary; //IE
	box-shadow: 0 0 0 0 #fff, 0 0 0 0 var(--primary);
	transition: all 200ms ease 0ms;

	@media (max-width: 768px) {
		margin: 8px 0 0 0.5rem;
	}
}

.btn-userarea {
	@extend .btn-notification;
	background: #eee;
	padding: 0;
	overflow: hidden;
	border-radius: 20px;
	margin-top: 8px;
	box-shadow: 0 0 0 0 #fff, 0 0 0 0 $primary; //IE
	box-shadow: 0 0 0 0 #fff, 0 0 0 0 var(--primary);
	transition: all 200ms ease 0ms;
	margin-left: 2rem;

	@media (max-width: 768px) {
		margin: 8px 0 0 0.5rem;
	}

	&:hover {
		box-shadow: 0 0 0 2px #fff, 0 0 0 3px $primary; //IE
		box-shadow: 0 0 0 2px #fff, 0 0 0 3px var(--primary);

		.ui-image {
			opacity: 0.9;
		}
	}
}

.userarea-id {
	font-size: 11px;
	line-height: 1.1rem;

	.usernumber {
		font-size: 9px;
		font-weight: 500;
		color: #173048 !important;
	}
}

.dropdown-content .dropdown-link {
	display: inline-block;
	padding-right: 1rem !important;
	color: $text !important; //IE
	color: var(--text) !important;
	font-weight: $font-weight-700; //IE
	font-weight: var(--font-weight-700);
	padding: 0.5rem 1rem;
	font-size: 14px;
}

#logout-button {
	color: white !important;
}

.logout-dropdown-item {
	background-color: gray;
}

.item-shortcut {
	display: flex !important;
}

#doc-big-button {
	background-color: red;
	color: white !important;
	text-align: center !important;
	border-radius: 15px;
	max-width: 12rem;
		display: inline-block;
}

#doc-small-text {
	color: red;
	font-size: xx-small;
}

#doc-button-item {
	text-align: center !important;
}

::v-deep .item-shortcut .icon-warning{
	svg {
		* {
			fill: #FF1515;
		}
	}
}

.notification-badge {
		position: absolute;
		top: 0px;
		left: 30px;
		border-radius: 19px;
		background-color: green;
		min-width: 0.5rem;
		min-height: 0.5rem;

		@media (max-width: 768px) {
			top: 10px;
			left: 35px;
		}
	}
</style>
